<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-row no-gutters>
        <v-col>
          <v-card class="pa-2 ma-2" outlined tile>
            <!-- toolbar -->
            <reference-tool-bar
              :title="options.title.main"
              :createItemTitle="options.title.create"
              :formTitle="formTitle"
              :editedIndex="editedIndex"
              :dialog="dialog"
              @save="save"
              @close="close"
              @open="open"
            >
              <template v-slot:formCreateEdit>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="organizationSelect"
                    :hint="
                      `Идентификатор организации - [${organizationSelect.id}]`
                    "
                    :items="organizationList"
                    item-text="nameRu"
                    item-value="id"
                    label="Организация"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.firstName"
                    label="Имя"
                    :hint="`Допускаются только буквы,  от 3 до 30 символов`"
                    :rules="[rules.required, rules.alfabet]"
                    autofocus
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.lastName"
                    label="Фамилия"
                    :hint="`Допускаются только буквы, от 3 до 30 символов`"
                    :rules="[rules.required, rules.alfabet]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.position"
                    label="Должность"
                    :hint="`От 3 символов`"
                    :rules="[rules.required, rules.min]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.subdivision"
                    label="Подразделение"
                    :hint="`От 3 символов`"
                    :rules="[rules.required, rules.min]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.email"
                    label="Электронная почта"
                    :hint="`Электронная почта`"
                    type="email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.phone"
                    label="Телефон"
                    :hint="`Телефон`"
                    :rules="[rules.required, rules.phone]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="statusSelect"
                    :hint="`Идентификатор статуса - [${statusSelect.id}]`"
                    :items="statusList"
                    item-text="nameRu"
                    item-value="id"
                    label="Статус"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
              </template>
            </reference-tool-bar>
            <!--Таблица с данными-->
            <reference-table
              :headers="options.headers"
              :items="options.data"
              @edit-item="editItem($event)"
              @remove-item="removeItem($event)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get, sync } from "vuex-pathify";
import { mapActions } from "vuex";
import { rules } from "@/util/rules";

export default Vue.extend({
  name: "Contact.vue",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ReferenceToolBar: () =>
      import("@/components/reference/ReferenceToolBar.vue")
  },
  computed: {
    options: get("reference/optionsContact"),
    organizationList: get("reference/getOrganizations"),
    statusList: get("reference/getStatus"),
    formTitle() {
      return this.editedIndex === -1 ? "Создать" : "Редактировать";
    }
  },
  data() {
    return {
      rules,
      dialog: false,
      editedIndex: -1,
      organizationSelect: 0,
      statusSelect: 0
    };
  },
  methods: {
    ...mapActions({
      fetchReference: "reference/fetchContact",
      fetchOrganizations: "reference/fetchOrganizations",
      fetchStatus: "reference/fetchStatus"
    }),
    initialize() {
      this.fetchStatus().then(result => {
        this.statusSelect = this.statusList.find(
          status => status.name == "Active"
        );
      });
      this.fetchOrganizations().then(result => {
        this.organizationSelect = this.organizationList[0];
      });
      this.fetchReference();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.options.editedItem = Object.assign({}, this.options.defaultItem);
        this.organizationSelect = this.organizationList[0];
        this.statusSelect = this.statusList[0];
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const model = {
        organizationId: this.organizationSelect.id,
        statusId: this.statusSelect.id,
        id: this.options.editedItem.id,
        firstName: this.options.editedItem.firstName,
        lastName: this.options.editedItem.lastName,
        position: this.options.editedItem.position,
        subdivision: this.options.editedItem.subdivision,
        email: this.options.editedItem.email,
        phone: this.options.editedItem.phone
      };
      if (this.editedIndex > -1) {
        this.$http.put(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      } else {
        model.id = 0;
        this.$http.post(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.options.data.indexOf(item);
      this.options.editedItem = Object.assign({}, item);
      this.organizationSelect = {
        id: item.organization.id,
        name: item.organization.NameRu
      };
      this.statusSelect = { id: item.status.id, name: item.status.NameRu };
      this.open();
    },
    removeItem(item) {
      if (confirm("Вы уверены что хотите удалить контакт?")) {
        const model = Object.assign({}, item);
        this.$http
          .delete(this.options.path.base, { data: model })
          .then(response => {
            if (response.status === "Ok") this.initialize();
          });
      }
    }
  },
  created() {
    this.initialize();
  }
});
</script>
